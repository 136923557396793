import initials from './initials';
import iniciales from './iniciales';
import fecha from './fecha';
import {getFiles, getServer, getServerConfiguracion} from './upload';
import money from "./money";
import download from "./download";

export default {
  initials,
  iniciales,
  fecha,
  getFiles,
  getServer,
  getServerConfiguracion,
  money,
  download
};
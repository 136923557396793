<template>
  <Loading :state="loading"></Loading>
  <router-view v-if="init"></router-view>
</template>
<script>
import Loading from "@/components/Loading";
import {defineComponent, watchEffect} from "vue";
import useAuth from "@/providers/auth";
import { useApi } from './use/useApi';
export default defineComponent({
  components: {Loading},
  setup(){
    const {init} = useAuth();
    const {loading} = useApi();

    watchEffect(() => {
      loading.active = !init.value;
    });

    return { loading, init }
  }
});
</script>
<style src="@/assets/css/global.css">

</style>
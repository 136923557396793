import { reactive } from 'vue';
import { api } from "@/providers/api";
import useAuth from '@/providers/auth';

const loading = reactive({
    active: false,
    message: 'Cargando...'
});

export function useApi() {

    const { logout } = useAuth();

    async function make(url: string, params: any){
        loading.active = true;
        try {
            const { data } = await api.post(url, params, {
                responseType: "json"
            });
            loading.active = false;
            if(data.reauth){
                logout();
            }
            return {
              data
            };
        } catch(err){
            loading.active = false;
            return {
                data: null
            };
        }
    }

    return { loading, make };
}
import { reactive, computed, toRefs } from 'vue';
import {api, storage} from "./api";
import Swal from "sweetalert2";

interface State {
    user?: any,
    init: boolean,
    token: string | undefined
}

const state = reactive<State>({
    user: null,
    init: false,
    token: undefined
});

export default function (){

    state.token = storage.get('token');

    function authCheck(){
        return new Promise((resolve) => {
            if(state.token === undefined){
                state.init = true;
                resolve(false);
            } else{
                !state.init && api.post('users/validate', { token: state.token }).then(({data}) => {
                    if(data.error) {
                        logout();
                        Swal.fire('Error', data.message, 'error');
                    } else {
                        state.user = data.user;
                    }
                    state.init = true;
                    resolve(true);
                });
            }
        });
    }

    function login(token: string, user: any){
        storage.set('token', token);
        state.token = token;
        state.user = user;
    }

    function logout(){
        storage.clear();
        state.token = undefined;
        state.user = null;
    }

    const isAdmin = computed(() => {
        return ['superadmin','administrador'].includes(state.user?.role);
    });

    const isOperaciones = computed(() => {
        return ['operaciones'].includes(state.user?.role);
    });

    const isCoordinador = computed(() => {
        return ['coordinador'].includes(state.user?.role);
    });

    const isSupervisor = computed(() => {
        return ['supervisor'].includes(state.user?.role);
    });

    const isCliente = computed(() => {
        return ['cliente'].includes(state.user?.role);
    });

    const isSoloVista = computed(() => {
        return ['solo vista'].includes(state.user?.role);
    });

    return { ...toRefs(state), login, logout, authCheck, isAdmin, isOperaciones, isCoordinador, isSupervisor, isCliente, isSoloVista };
}
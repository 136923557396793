import './styles/quasar.scss'
import 'quasar/src/css/index.sass'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'

import quasarLang from 'quasar/lang/es';
import Dialog from 'quasar/src/plugins/Dialog.js';;

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    Dialog
  },
  lang: quasarLang
}

import { createApp } from 'vue';
import useAuth from '@/providers/auth';
import App from '@/App.vue'
import router from "@/router";
import filters from "@/filters/filters";

import 'intl-tel-input/build/css/intlTelInput.css';
import '@vueform/toggle/themes/default.css';
import VueGoogleMaps from 'vue-google-maps-community-fork'

//Quasar
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from "@/quasar-user-options";

//Middleware
const { authCheck } = useAuth();

// @ts-ignore
const app = createApp(App).use(Quasar, quasarUserOptions);

//Filters
app.config.globalProperties.$filters = filters;

//Auth
authCheck().then(() => {
    app.use(router);
    return router.isReady();
});
//Plugins
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAQqxOsWQ7J_NH6L0MFqHV-zpyVqnEfq74',
    },
});

//Mount
router.isReady().then(() => {
    app.mount('#app');
});
